import React, { useEffect, useState } from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CodeMirror from '@uiw/react-codemirror';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useWeb3React } from '@web3-react/core'
import { GetTokens } from "../../../App";
import Web3 from 'web3';

var callback;

export function OnViewCsvExampleRequest(_callback){
  callback = _callback;
}

export function validateCSV(csv){
    var list      = csv.split("\n");
    if(list.length <= 1){
        throw new Error("More than one account must be entered");
    }
    var addresses = list.map(e => e.split(",")[0]);        
    var i = 0;
    for(var a of addresses){
        if(!Web3.utils.isAddress(a)){
            throw new Error("Invalid address " + a + " on line " + (i+1).toString());
        }
        i++;
    }
}
export function validateAmounts(csv, balance){
    var list      = csv.split("\n");    
    var amounts = list.map(e => e.split(",")[1]);        
    var i = 0;
    var total = 0;
    for(var a of amounts){
        total = total + parseFloat(a);
    }
    balance = balance / 1e18;
    if(total > balance){
        throw new Error("Insuficient balance");
    }
}
function Prepare(props){
    const [data, setData] = useState([]);
    const {active, account, chainId} = useWeb3React();
    const inputFileRef = React.useRef();
    const [error, setError] = useState("");
    const [csv, setCSV] = useState("");    
    useEffect(() => {
      if(!account){        
      }else{
        if(data.length == 0 || localStorage.getItem("tokens_chain") != chainId){          
          GetTokens(account, chainId).then((result) => {
            var r = result;            
            setData(r);
            localStorage.setItem("tokens_chain", chainId);
          });
        }       
      }
    });
    const FORM_LABEL_STYLE         = {fontSize: "14px", fontFamily: "Montserrat", fontWeight: "bold", color: "rgba(0,0,0,0.6)", width: "100%"};
    const UPLOAD_FILE_BUTTON_STYLE = {position: "absolute", bottom: "8px", right: "8px", fontWeight: "bold", fontSize: "12px"};
    return     <Card.Body>    
     <Card.Text style={{textAlign: "left"}}>
      <div style={{display: "flex"}}>
          <div style={{flex: "2"}}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={FORM_LABEL_STYLE}>Token address</Form.Label>              
              <Typeahead
                id={0}
                onChange={(selected) => {
                  // Handle selections...
                  if(selected.length == 0){
                    return;
                  }
                  var selectedTokenAddress = selected[0].id;
                  props.wizardData.token = selected[0];
                }}                  
                options={data.map(e => {
                  return {
                    label: `${e.symbol} ${e.token_address} (${Web3.utils.fromWei(e.balance)} ${e.symbol})`, 
                    id: e.token_address,     
                    symbol: e.symbol, 
                    balance: parseInt(e.balance.toString()), 
                    address: e.token_address               
                  }
                })}
              />
          </Form.Group>
          </div> 
      </div>    
      <Form.Label style={FORM_LABEL_STYLE}><span>CSV Address list</span> <span onClick={() => {
        if(callback != null){
            callback();
        }
      }} style={{fontWeight: "bold", color: "#565698", cursor: "pointer", float: "right"}}>View example</span></Form.Label>
      <div style={{position: "relative"}}>
        <CodeMirror value={csv} height="200px" onChange={(value) => {props.wizardData.csv = value}} />
        <input accept=".csv" ref={inputFileRef} onChange={(event) => {        
          var read = new FileReader();
          read.readAsBinaryString(event.target.files[0]);
          read.onloadend = () => {
            setCSV(read.result);
          }
        }} type="file" style={{display: "none"}}></input>
        <Button onClick={() => {
          inputFileRef.current.click();
        }} className="gradient" style={UPLOAD_FILE_BUTTON_STYLE} variant="primary">Upload file</Button>
      </div>
    </Card.Text>
    <div style={{display: error.length == 0 ? "none" : "block",borderRadius: "4px", background: "rgba(255,0,0,0.4)", marginBottom: "1rem"}}>
        <span style={{fontSize: "12px"}}>{error}</span>
      </div>
    {active == true ? <Button onClick={() => {        
        try{            
            validateCSV(props.wizardData.csv);
            validateAmounts(props.wizardData.csv, props.wizardData.token.balance);
            props.onNext();
        }catch(e){
            setError(e.message);
        }
    }} className="gradient" style={{width: "100%"}} variant="primary">Next</Button> : <Button className="gradient" style={{width: "100%"}} variant="primary" disabled>Connect first</Button>}
    
  </Card.Body>
}


export default Prepare;