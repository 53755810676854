import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from "react";
import ConnectWallet, { OnConnectRequest } from './ConnectWallet';
import {
    Link
  } from "react-router-dom";
import { useWeb3React } from '@web3-react/core';
import { ContractLoader } from '../contracts/ContractLoader';


let _callback;

export function OnVipRequest(callback){
    _callback = callback;
}

export function TriggerVipRequest(){
    if(_callback != null){
        _callback();
    }
}

function Menu(){    
    const {active, library, account} = useWeb3React();
    const [level, setLevel] = useState({
        level: 0
    });
    const GOLD_BUTTON = {marginRight: "0.5rem", borderColor: "gold", color: "gold", fontSize: "12px", display: "flex", alignItems: "center"};
    const LINK_STYLE = {textDecoration: "none", fontSize: "14px", color: "white", marginRight: "14px", fontWeight: "bold", cursor: "pointer", alignSelf: "center"};
    const vip_icons = {        
        1: <i style={{fontSize: "18px", marginLeft: "0.55rem"}} class="bi bi-1-circle"></i>, 
        2: <i style={{fontSize: "18px", marginLeft: "0.55rem"}} class="bi bi-2-circle"></i>, 
        3: <i style={{fontSize: "18px", marginLeft: "0.55rem"}} class="bi bi-3-circle"></i>, 
    }    
    const contract = active == true ? ContractLoader(library, "sendfly") : null;
    useEffect(() => {
        if(active == true && contract != null){
            contract.contract.methods.currentLevel(account).call().then((vip) => {
                setLevel({
                    level: parseInt(vip["level"])
                });
            });
        }
    })    

    return <div style={{display: "flex"}}>
        <a style={LINK_STYLE} href="/"><span style={LINK_STYLE}>Send</span></a>
        <a style={LINK_STYLE} href="/tutorial"><span style={LINK_STYLE}>Tutorial</span></a>
        <a style={LINK_STYLE} href="/faq"><span style={LINK_STYLE}>FAQ</span></a>
        <Button style={GOLD_BUTTON} onClick={() => {
            if(_callback != null){
                _callback();
            }
        }} className="unborder">Vip {level.level == 0 ? <></> : vip_icons[level.level]}</Button>
        <ConnectWallet></ConnectWallet>
    </div>;
}   

export default Menu;