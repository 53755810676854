import Button from 'react-bootstrap/Button';
import React from "react";
import { useWeb3React } from '@web3-react/core';
import Wallet from './Wallet';


let _callback;

export function OnConnectRequest(callback){
    _callback = callback;
}
function ConnectWallet(){
    const { active, account, chainId } = useWeb3React();  
    if(active == false){
        return <Button style={{fontSize: "12px"}} onClick={() => {
            if(_callback != null){
                _callback();
            }
        }} className="unborder"> Connect </Button>;    
    }
    return <><Wallet account={account}></Wallet></>;
}

export default ConnectWallet;   