import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from "@web3-react/injected-connector";
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import CodeMirror from '@uiw/react-codemirror';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import NavBar from './components/NavBar';
import { OnConnectRequest } from './components/ConnectWallet';
import { OnVipRequest } from './components/Menu';
import Wizard from './components/Wizard/Wizard';
import Web3 from 'web3';
import { OnChangeNetworkRequest } from './components/Wallet';
import FAQ from './pages/FAQ';
import { ContractLoader } from './contracts/ContractLoader';
import { OnViewCsvExampleRequest } from './components/Wizard/Steps/Prepare';
import TransactionStatusToast, { TriggerTransactionStatus } from './components/TransactionStatusToast';
import Tutorial from './pages/Tutorial';




const Injected = new InjectedConnector({
  supportedChainIds: [1337, 56, 97, 1, 137]
});



function App() {
  const { activate, deactivate, active, library, account} = useWeb3React();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showVip, setShowVip] = useState(false);
  const handleCloseVip = () => setShowVip(false);
  const handleShowVip = () => setShowVip(true);

  const [showChangeNetwork, setShowChangeNetwork] = useState(false);
  const handleCloseChangeNetwork = () => setShowChangeNetwork(false);
  const handleShowChangeNetwork = () => setShowChangeNetwork(true);

  const [showCSVExample, setShowCSVExample] = useState(false);
  const handleCloseCSVExample = () => setShowCSVExample(false);
  const handleShowCSVExample = () => setShowCSVExample(true);


  OnConnectRequest(() => handleShow());
  OnVipRequest(() => handleShowVip());
  OnChangeNetworkRequest(() => handleShowChangeNetwork());
  OnViewCsvExampleRequest(() => handleShowCSVExample());
  useEffect(() => {
    if(active == true){      
      GetNativeBalance(account, library).then(e => localStorage.setItem("balance", e));
    }
  })
  if(active == false){
    if(localStorage.getItem("account") != null){
        activate(Injected);
    }
  }

  ContractLoader("", "sendfly");
  return (
    <div className="App">
      <NavBar></NavBar>
      <div className="App-Body">
        <Router>
          <Routes>
            <Route path="/" element={<Wizard></Wizard>} />
            <Route path="/tutorial" element={<Tutorial></Tutorial>} />
            <Route path="/faq" element={<FAQ></FAQ>} />
          </Routes>
        </Router>
      </div>
      <div className="App-Footer">
        <span style={{ fontWeight: "bold", fontSize: "14px", color: "white" }}>Version: 0.1</span>
        <hr></hr>
        <div style={{ display: "flex", justifyContent: "space-between", width: "700px", margin: "0 auto" }}>
          <div style={{ marginTop: "0.5rem" }}>
            <a style={{ textDecoration: "none", color: "white" }} href="">Contract<i style={{ marginLeft: "0.5rem", color: "#76ff76" }} class="bi bi-patch-check-fill"></i></a>
          </div>
          <div style={{ marginTop: "0.5rem" }}>
            <a style={{ textDecoration: "none", color: "white" }} href="https://telegram.com">
              <i style={{ fontSize: "24px" }} class="bi bi-telegram"></i>
            </a>
            <a style={{ textDecoration: "none", color: "white", marginLeft: "0.75rem" }} href="https://twitter.com/">
              <i style={{ fontSize: "24px" }} class="bi bi-twitter"></i>
            </a>
            <a style={{ textDecoration: "none", color: "white", marginLeft: "0.75rem" }} href="https://github.com/">
              <i style={{ fontSize: "24px" }} class="bi bi-github"></i>
            </a>
          </div>
        </div>
      </div>
      <ModalWallet handleClose={() => { handleClose() }} show={show}></ModalWallet>
      <ModalVip handleClose={() => { handleCloseVip() }} show={showVip}></ModalVip>
      <ModalNetworks handleClose={() => handleCloseChangeNetwork()} show={showChangeNetwork}></ModalNetworks>
      <ModalCsvExample handleClose={() => handleCloseCSVExample()} show={showCSVExample}></ModalCsvExample>
      <TransactionStatusToast></TransactionStatusToast>
    </div>
  );
}

export async function GetNativeBalance(account, library) {
  return library.getBalance(account);
}

export async function GetTokens(address, network) {
  var chain = "0x"+network.toString(16);
  var nativeBalance = localStorage.getItem("balance") ?? 0;
  const nativeTokens = {
    56: {
      symbol: "BNB", 
      balance: nativeBalance.toString(), 
      token_address: "Native chain token"
    },
    97: {
      symbol: "BNB", 
      balance: nativeBalance.toString(), 
      token_address: "Native chain token"
    },
    1: {
      symbol: "ETH", 
      balance: nativeBalance.toString(), 
      token_address: "Native chain token"
    },
    137: {
      symbol: "MATIC", 
      balance: nativeBalance.toString(), 
      token_address: "Native chain token"
    }
  }
  var native = nativeTokens[network];
  var url = "https://deep-index.moralis.io/api/v2/" + address + "/erc20?chain=" + chain;
  var fetched = await fetch(url, {
    headers: {
      "X-API-Key": "leQNTj306UJXTgMN1PKRHbvnOqFqjgXBjeiVQDoIuMEfqyR2BKfkyUpjsO22Q2rS",
      "Accept": "application/json"
    }
  });
  var json = await fetched.json();
  json.push(native);
  return json;
}

function ValidateCSVInput() { }
function ValidateTokenAddress() { }



function ModalWallet(props) {
  const { activate, deactivate } = useWeb3React();
  const { active, chainId, account } = useWeb3React();
  if(active == true){
    if(account){
       localStorage.setItem("account", account);
    }
  }
  return (
    <>
      <Modal show={props.show}>
        <Modal.Header closeButton onHide={props.handleClose}>
          <Modal.Title>Connect</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            <ListGroup.Item
              style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              onClick={async () => {
                await activate(Injected);                
                props.handleClose();
              }}><img src={"https://seeklogo.com/images/M/metamask-logo-09EDE53DBD-seeklogo.com.png"} style={{ width: "20px", marginRight: "0.5rem" }}></img>Metamask</ListGroup.Item>
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  );
}
function ModalCsvExample(props) {
  const { activate, deactivate } = useWeb3React();
  const { active, chainId, account } = useWeb3React();
  return (
    <>
      <Modal size="lg" centered show={props.show}>
        <Modal.Header closeButton onHide={props.handleClose}>
          <Modal.Title>Example csv</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <CodeMirror value={"0x18fe3C91Ff0F71827146f03E120D8A26D39B5339, 0.0005 \n0x18fe3C91Ff0F71827146f03E120D8A26D39B5339, 0.0005"} height="200px"/>
        </Modal.Body>
      </Modal>
    </>
  );
}

function ModalVip(props) {
  const {library, account, active} = useWeb3React();  
  const [purchased, setPurchased] = useState([]);
  const [purchasedLoaded, setPurchasedLoaded] = useState(false);
  const vips = [
    {level: 1, text: "Vip Lv. 1 (1 day)", price: "1 BNB", priceAmount: Web3.utils.toWei('1', 'ether') },
    {level: 2, text: "Vip Lv. 2 (7 days)", price: "3 BNB", priceAmount: Web3.utils.toWei('3', 'ether') },
    {level: 3, text: "Vip Lv. 3 (30 days)", price: "5 BNB", priceAmount: Web3.utils.toWei('5', 'ether') },
    {level: 4, text: "Vip Lv. 4 (90 days)", price: "10 BNB", priceAmount: Web3.utils.toWei('10', 'ether') },
  ]
  const contract =  active == true ? ContractLoader(library, "sendfly") : null;
  const info = "With the vip plan, you will be able to make transactions without fees (you will only pay the network fees).";
  const purchase = async (level) => {
    if(contract == null){
      return;
    }
    return contract.contract.methods.purchaseVip(level).send( {
      from: account, 
      value: vips.find(e => e.level == level).priceAmount
    });
  }
  const getPurchased = async () => {
    if(contract == null){
      return;
    }
    return contract.contract.methods.currentLevel(account).call();
  }
  if(props.show == true){
    if(purchasedLoaded == true) {
        setPurchasedLoaded(false);
    }
  }  
  useEffect(() => {
    if(purchasedLoaded == false){
      loadPurchased();
    }
  });
  const loadPurchased = () => {
    getPurchased().then((purchased) => {
      setPurchased(purchased);
      setPurchasedLoaded(true);
    });
  }
  return <>
    <Modal show={props.show}>
      <Modal.Header closeButton onHide={props.handleClose}>
        <Modal.Title>Vip plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ borderRadius: "4px", background: "rgba(0,0,0,0.1)", padding: "0.5rem" }}>
          <span style={{ fontSize: "12px" }}>{info}</span>
        </div>
        <ListGroup>
          {vips.map(e => {
            var isPurchased = !purchased ? false : purchased["level"] == e.level;            
            return <ListGroup.Item className="separated" style={{ cursor: "pointer", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
              <span style={{ fontSize: "14px" }}>{e.text}</span>
              <div style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}><span style={{ marginRight: "0.5rem" }}>{e.price}</span> 
              <Button disabled={isPurchased} onClick={isPurchased == true ? () => {} : async () => {
                alert("Purchasing");
                await purchase(e.level);
                loadPurchased();
              }} className="gradient" style={{ fontWeight: "bold", fontSize: "12px" }} variant="primary">{isPurchased == true ? "Purchased" : "Purchase"}</Button> </div>
            </ListGroup.Item>
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  </>
}
function ModalNetworks(props) {
  const {chainId, library} = useWeb3React();
  const mainNetworks = [
    { 
      text: "BSC (Binance Smart Chain)", 
      icon: "https://seeklogo.com/images/B/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com.png", 
      chainId: 56, 
      chainName: "Binance Smart Chain", 
      symbol: "BNB", 
      explorer: "https://bscscan.com/",
      rpc: "https://bsc-dataseed1.binance.org/"
    },
    { 
      text: "ETH (Ethereum Mainnet)", 
      icon: "https://cdn4.iconfinder.com/data/icons/cryptocoins/227/ETH-alt-512.png", 
      chainId: 1, 
      chainName: "Ethereum Mainnet", 
      symbol: "ETH",
      explorer: "https://etherscan.io/",
      rpc: "https://rpc.ankr.com/eth"
    },
    { 
      text: "MATIC (Polygon Mainnet)", 
      icon: "https://cdn3d.iconscout.com/3d/premium/thumb/polygon-matic-cryptocurrency-5108587-4263924.png", 
      chainId: 137, 
      chainName: "Polygon", 
      symbol: "MATIC", 
      explorer: "https://polygonscan.com/",
      rpc: "https://polygon-rpc.com"
    },
    { 
      text: "BSC (Binance Smart Chain Testnet)", 
      icon: "https://seeklogo.com/images/B/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com.png", 
      chainId: 97, 
      chainName: "Binance Smart Chain (Testnet)", 
      symbol: "BNB", 
      explorer: "https://testnet.bscscan.com/",
      rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/"
    },
  ]
  const switchNetwork = async (chainId) => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + chainId.toString(16) }],
      });
    } catch (switchError) {
      // 4902 error code indicates the chain is missing on the wallet
      if (switchError.code === 4902) {
        try {
          var network = mainNetworks.find(e => e.chainId == chainId);
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId:  "0x" + chainId.toString(16),
                rpcUrls: [network.rpc],
                chainName: network.chainName,
                nativeCurrency: { name: network.symbol, decimals: 18, symbol: network.symbol },
                blockExplorerUrls: [network.explorer],
                iconUrls: [network.icon]
              }
            ],
          });
        } catch (error) {
           console.error(error)
        }
      }
    }
  };
  return <>
    <Modal show={props.show}>
      <Modal.Header closeButton onHide={props.handleClose}>
        <Modal.Title>Choose a network</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {mainNetworks.map(e => {
            var current = e.chainId == chainId;
            return <ListGroup.Item onClick={async () => {
              await switchNetwork(e.chainId);
              props.handleClose();
            }} className="separated" style={{ cursor: "pointer", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
              <div><span><img src={e.icon} style={{ width: "20px" }}></img></span><span style={{ marginLeft: "14px", fontSize: "12px" }}>{e.text}</span>{current == true ? <i style={{ marginLeft: "0.5rem", color: "#2b892b" }} class="bi bi-patch-check-fill"></i> : <></>}</div>
            </ListGroup.Item>
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  </>
}

export function Loader(){
  return <>
        <div class="loadingio-spinner-rolling-o18mgj7sztd"><div class="ldio-dwpebok3bgh">
      <div></div>
      </div></div>
  </>
}

export default App;
