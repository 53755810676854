
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { Loader } from '../App';

var callback;

export function OnTransactionStatus(_callback){
    callback = _callback;
}

export function TriggerTransactionStatus(options){
    callback(options.show, options.message, options?.loading ?? false);
}

export default function TransactionStatusToast(props){
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    OnTransactionStatus((_show, _message, loading) => {
        setShow(_show);
        setMessage(_message);
        setLoading(loading);
    });
    return  <ToastContainer className="p-3" position={"bottom-center"}>
    <Toast show={show}>
      <Toast.Header closeButton={false}>
        <strong className="me-auto">Transaction status</strong>        
      </Toast.Header>
      <Toast.Body style={{textAlign: "left"}}>
         {message}
      </Toast.Body>
    </Toast>
  </ToastContainer>
}