import { useWeb3React } from "@web3-react/core";
import React, {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Web3 from "web3";

var callback;

export function OnChangeNetworkRequest(_callback){
    callback = _callback;
}

function Wallet(props){
    const {library, chainId} = useWeb3React();
    const [balance, setBalance] = useState(0);
    const chainIcons = {
        56: "https://seeklogo.com/images/B/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com.png",
        97: "https://seeklogo.com/images/B/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com.png", 
        1: "https://cdn4.iconfinder.com/data/icons/cryptocoins/227/ETH-alt-512.png", 
        137: "https://cdn3d.iconscout.com/3d/premium/thumb/polygon-matic-cryptocurrency-5108587-4263924.png"
    }
    const chainNames = {
        56: "Binance Smart Chain",
        97: "Binance Smart Chain ( Testnet )",
        1: "Ethereum Mainnet", 
        137: "Polygon"
    }
    const chainSymbol = {
        56: "BNB",
        97: "BNB", 
        1: "ETH", 
        137: "MATIC"
    }
    useEffect( () => {
        library?.getBalance(props.account).then((balance) => {
            var b = balance.toString();
            var parsed = parseFloat(Web3.utils.fromWei(b)).toFixed(4);
            setBalance(parsed);
        })
        
    })
    
    return <>
        <Button onClick={() => {
            if(callback != null){
                callback();
            }
        }} style={{fontSize: "12px", marginRight: "0.5rem", display: "flex", alignItems: "center"}} className="unborder">
            <img style={{width: "20px"}} src={chainIcons[chainId]}></img> <span style={{marginLeft: "0.5rem"}}>{chainNames[chainId]}</span>
        </Button> 
        <Button style={{fontSize: "12px", marginRight: "0.5rem", display: "flex", alignItems: "center"}} className="unborder">{balance} {chainSymbol[chainId]} <span style={{padding: "0.25rem 0.75rem", borderRadius: "4px", marginLeft: "0.5rem", backgroundColor: "rgba(0,0,0,0.5)"}}>{props.account.substring(0,13) + "..."}</span> </Button>         
    </>
}

export default Wallet;