import React, {useState, useEffect} from "react";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useWeb3React } from "@web3-react/core";
import { ContractLoader } from "../../../contracts/ContractLoader";
import Web3 from "web3";
import { TriggerTransactionStatus } from "../../TransactionStatusToast";
import { TriggerVipRequest } from "../../Menu";

function Send(props){
    const explorers = {
        56: "https://bscscan.com/tx/", 
        97: "https://testnet.bscscan.com/tx/"
    }
    const {library, account, chainId} = useWeb3React();
    const [loaded, setLoaded] = useState(false);
    const [level, setVipLevel] = useState([]);
    const [aproxCost, setAproxCost] = useState(0);
    const [sended, setSended] = useState(false);
    const [sending, setSending] = useState(false);
    const [txHash, setTxHash] = useState("");
    const COL_STYLE  = {border: "solid 1px rgba(0,0,0,0.2)", borderRadius: "4px", margin: "0.25rem", display: "flex", flexDirection: "column", padding: "0.5rem"}
    var splitted     = props.wizardData.csv.split("\n");
    var total_amount = splitted.map((e) => e.split(",")[1]).map(e => parseFloat(e)).reduce((a,b) => a+b);    
    var csv = splitted.map(e => {
        var c = e.split(",");
        return {
            wallet: c[0], 
            amount: c[1]
        }
    });
    const contract = ContractLoader(library, "sendfly");    
    useEffect(() => {
        if(loaded == false){                    
            contract.contract.methods.currentLevel(account).call().then((_level) => {
                estimateCost(csv, props.wizardData.token.address, _level).then((value) => {                                        
                    setAproxCost(parseFloat(Web3.utils.fromWei(value.toString())) + parseFloat(Web3.utils.fromWei(_level["fee"])));
                    setVipLevel(_level);
                    setLoaded(true);  
                }).catch(e => {                    
                    if(props.setStep){
                        props.setStep(1);
                    }
                })                
            });
        }
    });
    const sendTokens = async (_csv, tokenAddress, onTransactionHash = null) => {        
        var wallets = _csv.map(e => e.wallet);
        var amounts = _csv.map(e => Web3.utils.toWei(parseFloat(e.amount).toString(), "ether"));      
        var sumAmounts = _csv.map(e => {
            return (parseFloat(e.amount))
        }).reduce((a,b) => a+b);       
        if(tokenAddress == "Native chain token"){
            return contract.contract.methods.distributeEther(wallets, amounts).send({ 
                from: account,             
                value: Web3.utils.toWei((parseFloat(Web3.utils.fromWei(level["fee"], 'ether')) + sumAmounts).toString(), "ether")
            });         
        }
        return contract.contract.methods.distribute(
            tokenAddress, 
            wallets, 
            amounts
        ).send({
            from: account, 
            value: level["fee"]
        });
    }
    const estimateCost = async (_csv, tokenAddress, _level) => {
        var avgGasPrice = 17000000000;
        var wallets = _csv.map(e => e.wallet);
        var amounts = _csv.map(e => {
            return (parseFloat(e.amount) * 1e18).toString()
        });     
        var sumAmounts = _csv.map(e => {
            return (parseFloat(e.amount))
        }).reduce((a,b) => a+b);             
        if(tokenAddress == "Native chain token"){
            var total = parseInt(Web3.utils.toWei((sumAmounts + parseFloat(Web3.utils.fromWei(_level["fee"])).toString()), 'ether'));
            var r = await contract.contract.methods.distributeEther(wallets, amounts).estimateGas({ 
                from: account, 
                value: Web3.utils.toWei((sumAmounts + parseFloat(Web3.utils.fromWei(_level["fee"])).toString()), 'ether')
            });     
            
            return ((parseInt(r) * avgGasPrice) + total).toString();    
        }
        var r = await contract.contract.methods.distribute(tokenAddress, wallets, amounts ).estimateGas({ 
            from: account,             
            value: Web3.utils.toWei("1", 'ether')
        });                    
        return ((parseInt(r) * avgGasPrice)).toString();
    }
    if(loaded == false){
        return <Card.Body>    
                <Card.Text style={{textAlign: "center"}}>
                    Checking...
                </Card.Text>    
            </Card.Body>
    }
    if(sended == true){
        return <Card.Body>    
            <Card.Text style={{textAlign: "center"}}>
                <h5>Transaction successfully confirmed!</h5>
                <Container>
                    <Row>
                        <Col style={COL_STYLE}>
                            <span style={{fontSize: "14px", opacity: "0.6", padding: "0.75rem"}}>Total recipients</span>
                            <h4 style={{fontWeight: "bold"}}>
                                {csv.length}
                            </h4>
                        </Col>
                        <Col style={COL_STYLE}>
                            <span style={{fontSize: "14px", opacity: "0.6", padding: "0.75rem"}}>Aprox. cost of operation</span>
                            <h5 style={{fontWeight: "bold", fontSize: "16px"}}>                                                                                                
                            { Web3.utils.fromWei((aproxCost * 1e18).toString())} BNB
                            </h5>
                        </Col>
                    </Row>
                    </Container>
                <Button style={{borderColor: "green", color: "black", marginTop: "1rem"}} onClick={() => {
                    var url = explorers[chainId] + txHash;
                    window.open(url, '_blank');
                }} className="unborder">View transaction</Button>
                <br></br>
                <Button style={{borderColor: "black", color: "black", marginTop: "1rem"}} onClick={() => {
                    if(props.setStep){
                        props.setStep(0);
                    }
                }} className="unborder">Do another</Button>
            </Card.Text>    
        </Card.Body>
    }
    return <Card.Body>    
                <Card.Text style={{textAlign: "center"}}>
                <Container>
                    <Row>
                        <Col style={COL_STYLE}>
                            <span style={{fontSize: "14px", opacity: "0.6", padding: "0.75rem"}}>Total recipients</span>
                            <h4 style={{fontWeight: "bold"}}>
                                {csv.length}
                            </h4>
                        </Col>
                        <Col style={COL_STYLE}>
                            <span style={{fontSize: "14px", opacity: "0.6", padding: "0.75rem"}}>Aprox. cost of operation</span>
                            <h5 style={{fontWeight: "bold", fontSize: "16px"}}>                                                                                                
                            {aproxCost} BNB                            
                            </h5>
                        </Col>
                    </Row>
                    </Container>
                    <span onClick={() => TriggerVipRequest()} style={{fontSize: "12px", fontWeight: "bold", color: "#565698", cursor: "pointer", float: "right", margin: "1rem"}}>VIP Plan?</span>
                </Card.Text>
                <Button disabled={sending} onClick={ async () => {
                    setSending(true);
                    TriggerTransactionStatus({show: true, message: "Sending transaction", loading: true});
                    var transaction = await sendTokens(csv, props.wizardData.token.address);
                    setSended(true);
                    setTxHash(transaction.transactionHash);
                    TriggerTransactionStatus({show: true, message: "Sended succesfully!"});
                    setTimeout(() => {
                        TriggerTransactionStatus({show: false, message: "Sended succesfully!"});
                    }, 2000);   
                    setSending(false);
                }} className="gradient" style={{width: "100%"}} variant="primary">Confirm</Button>
            </Card.Body>
}

export default Send;