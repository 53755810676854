import React, { useState } from "react";
import Dash from "./Extra/Dash";
import Approve from "./Steps/Approve";
import Prepare from "./Steps/Prepare";
import Send from "./Steps/Send";
import Card from 'react-bootstrap/Card';
var wizardData  = {
    token: {}, 
    csv: "" 
};
function Wizard(){
    const [step, setStep] = useState(0);
    const OnSetStep = (step) => setStep(step);
    const OnNext = () => {
        var s = step + 1;
        setStep(s);        
    }
    const childrens = [
        <Prepare wizardData={wizardData} onNext={OnNext}></Prepare>, 
        <Approve wizardData={wizardData} onNext={OnNext}></Approve>, 
        <Send setStep={OnSetStep} wizardData={wizardData} onNext={OnNext}></Send>
    ];
    return <>
          <div style={{display: "flex", margin: "1rem 0rem"}}>
            <Dash currentStep={step} setStep={OnSetStep} text={"Prepare"} step={0} number={1}></Dash>
            <Dash currentStep={step} setStep={OnSetStep} text={"Approve"} step={1} number={2}></Dash>
            <Dash currentStep={step} setStep={OnSetStep} text={"Send"} step={2} number={3}></Dash>
        </div>
        <Card style={{ width: '100%' }}>  
            <>{childrens[step]}</>
        </Card>
    </>
}

export default Wizard;